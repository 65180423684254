import React, { useState, useEffect, useInsertionEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";



const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = date.toLocaleString("en-US", {
    month: "long",
    timeZone: "UTC",
  });
  const day = date.getUTCDate().toString().padStart(2, "0");
  return `${month} ${day}, ${year}`;
};

const formatTime = (dateString) => {
  const date = new Date(dateString);
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  return `${hours}:${minutes} ${ampm}`;
};

const EventDetailsDialog = ({
  selectedEvent,
  setSelectedEvent,
}) => {
  const allDay = selectedEvent?.allDay;
  const theme = useTheme();

  const API_URL = process.env.REACT_APP_API_URL;

  // this is need because when events are label as 'allDay' whether its one fullday or 'n'-fulldays, the end date associated with the event is always the day after the end date selected
  // this is just a quirk of the react full-calender were using
  // hence why use need a method like this before displaying the end date or doing comparisons with the end date
  const adjustEndDateForAllDayEvent = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1);
    return date.toISOString();
  };
  

  return (
    <Dialog
      open={Boolean(selectedEvent)}
      onClose={() => setSelectedEvent(null)}
      aria-labelledby="event-details-title"
      sx={{
        "& .MuiDialog-container .MuiPaper-root": {
          borderRadius: 2,
          padding: 2,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
          width: '100%',
          verflow: 'hidden'
        }}
      >
        <DialogTitle
          id="event-details-title"
          sx={{ 
            fontWeight: 'bold', 
            flexGrow: 1,
            textAlign: 'center',
            marginRight: 'auto',
            whiteSpace: 'normal', 
            wordBreak: 'break-word', 
            overflowWrap: 'break-word', 
            maxWidth: 'calc(100%)', 
          }}
        >
          {selectedEvent?.title}
        </DialogTitle>
      </Box>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography sx={{ color: "#333", fontWeight: "medium" }}>
          <strong>Date:</strong>{" "}
          {allDay ? (
            selectedEvent?.start && selectedEvent?.end ? (
              formatDate(selectedEvent.start) === formatDate(adjustEndDateForAllDayEvent(selectedEvent.end)) ? (
                formatDate(selectedEvent.start)
              ) : (
                formatDate(selectedEvent.start) + " - " + formatDate(adjustEndDateForAllDayEvent(selectedEvent.end))
              )
            ) : ""
          ):(
              selectedEvent?.start && selectedEvent?.end ? (
                formatDate(selectedEvent.start) === formatDate(selectedEvent.end) ? formatDate(selectedEvent.start) : formatDate(selectedEvent.start) + ' - ' + formatDate(selectedEvent.end) 
              ) : ""
          )}
          
        </Typography>

        {allDay ? (
            ""
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography sx={{ color: "#333", fontWeight: "medium" }}>
              <strong>Start:</strong>{" "}
              {selectedEvent?.start ? formatTime(selectedEvent.start) : ""}
            </Typography>
            <Typography sx={{ color: "#333", fontWeight: "medium" }}>
              <strong>End:</strong>{" "}
              {selectedEvent?.end ? formatTime(selectedEvent.end) : ""}
            </Typography>
          </Box>
        )}

        <Typography sx={{ color: "#333", fontWeight: "medium" }}>
          <strong> Description:</strong>{" "}
          {selectedEvent.description}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default EventDetailsDialog;