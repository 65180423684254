// calendarUtil.js
import axios from 'axios';
import Cookies from 'js-cookie';
import PersonIcon from '@mui/icons-material/Person';

const API_URL = process.env.REACT_APP_API_URL;

export const formatTime = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getUTCHours().toString().padStart(2, "0"); // Convert to 24-hour format and pad with leading zero if needed
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`; // Return in HH:mm format
};

export const getCurrentTime = (offsetHours = 0) => {
    const now = new Date();
    if(now.getHours > now.getHours+offsetHours){
      now.setHours(23);
        now.setMinutes(59);
    } else {
      now.setHours(now.getHours() + offsetHours); // Add the offset to the current hours
    }
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};

export const handleDeleteEvent1 = async (event, calendarRef, setSelectedEvent, handleError) => {
    try {
        // Ensure they have write access
        const response = await axios.delete(
            `${API_URL}/calander-event/delete/${event.eventId}`,
            {
                withCredentials: true,
                withXSRFToken: true,
            }
        );

        setSelectedEvent(null);

        const calendarApi = calendarRef.current.getApi(); 
        const calendarEvent = calendarApi.getEventById(event.eventId); 

        if (calendarEvent) {
            calendarEvent.remove(); 
        }
    } catch (error) {
        handleError(error);
    }
};

export const handleEditEvent1 = async (
    event,
    eventData,
    calendarRef,
    setIsEventDialogOpen,
    setEventToEdit,
    handleError
) => {
    try {
        const response = await axios.put(
            `${API_URL}/calander-event/edit`,
            eventData,
            {
                withCredentials: true,
                withXSRFToken: true,
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        const calendarApi = calendarRef.current.getApi();
        const calendarEvent = calendarApi.getEventById(eventData.eventId);

        if (calendarEvent) {
            calendarEvent.remove(); // Remove the existing event

            calendarApi.addEvent({
                id: response.data.eventId,
                title: response.data.title,
                start: response.data.start,
                end: response.data.end,
                allDay: response.data.allDay,
                backgroundColor: response.data.colourCode,
                icon: response.data.sharedWithClient ? <PersonIcon /> : "",
                extendedProps: {
                    shareWithClient: response.data.sharedWithClient,
                    description: response.data.description,
                    projectId: response.data.projectId,
                    employeeId: response.data.employeeId,
                    businessId: response.data.businessId,
                },
            });
        }

        setIsEventDialogOpen(false);
        setEventToEdit(null);
    } catch (error) {
        handleError(error);
    }
};

export const handleError1 = (error) => {
    if (error.response && error.response.status === 403) {
        alert("You are not authorized to perform this action. Please contact Admin for help.");
    } else {
        console.error("An error occurred:", error);
    }
};

export const formatDateToISO = (dateStr) => {
    return `${dateStr}T12:00:00Z`; // Append 'T00:00:00Z' to make it an ISO 8601 format
};

export const formatEndDateToISO = (dateStr) => {
    const date = new Date(dateStr);
    date.setDate(date.getDate() + 1); // Add one day to the date
    return `${date.toISOString().split('T')[0]}T12:00:00Z`; // Convert to ISO format and adjust time
};
