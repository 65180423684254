import { useState, useEffect, useRef } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  useTheme,
  Grid,
  FormControlLabel,
  FormControl,
  Switch,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "../../CalendarStyles.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonIcon from '@mui/icons-material/Person';

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ACCESS_CATEGORIES from "../../constants/AccessCatagories"
import checkWriteAccess from "../../util/CheckWriteAccess";
import EventDetailsDialog from "../dashboard/EventDetailsDialog";
import ConfirmationDialog from "../../generic/ConfirmationDialog";
import EventDialog from "./eventDialog";

import {formatDateToISO,formatEndDateToISO, formatTime, getCurrentTime, handleDeleteEvent1, handleEditEvent1, handleError1 } from './calendarUtil';



const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentEvents, setCurrentEvents] = useState([]);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { clientUserId } = useParams();
  const [project, setProject] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;

  // ref to store the calendar instance
  const calendarRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [selectedDate, setSelectedDate] = useState({
    startStr: "",
    endStr: "",
  });

  const [isEventDialogOpen, setIsEventDialogOpen] = useState(false);
  const [newEventTitle, setNewEventTitle] = useState("");

  const [newEventStartTime, setNewEventStartTime] = useState("");
  const [newEventEndTime, setNewEventEndTime] = useState("");
  const [isAllDayEvent, setIsAllDayEvent] = useState(true);

  const [showTimeInputs, setShowTimeInputs] = useState(false);
  const [allEvents, setAllEvents] = useState([]);

  const [isCalendarInitialized, setIsCalendarInitialized] = useState(false);

  const [startTime, setStartTime] = useState(getCurrentTime());
  const [endTime, setEndTime] = useState(getCurrentTime(1));

  const [selectedEvent, setSelectedEvent] = useState(null);

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false); // State for confirmation dialog (used for deleting event)
  const [eventToDelete, setEventToDelete] = useState(null); // State to store event to delete
  const [eventToEdit, setEventToEdit] = useState(null); // State to store event to delete
  const [isEdit, setIsEdit] = useState(null);
  const [shareWithClient, setShareWithClient] = useState(false);
  const [newDescription, setNewDescription] = useState("");
  const [eventBackgroundColor, setEventBackgroundColor] = useState("#3788d8"); 



  useEffect(() => {
    if (isCalendarInitialized) {
      fetchEvents();
    }
  }, [isCalendarInitialized]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/calander-event/get-event-for-projectId/${projectId}`,
        { withCredentials: true }
      );
      const eventDTOs = response.data;
      //setAllEvents(eventDTOs);
      addEventsToCalendar(eventDTOs);
    } catch (error) {
      console.error("Error fetching events:", error);
      if (error.response) {
        if (error.response.status === 403){
          alert("You are not authorized to view calander events. Please contact Admin for help.");
        }
      }
      // Handle error appropriately
    }
  };

  // Fetch projec tdata
  const fetchProjectData = async () => {
    try {
      const response = await fetch(
        `${API_URL}/project/get-single-project-for-client?projectId=${projectId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );
      console.log(response.data);

      const data = await response.json();
      setProject(data);
    } catch (err) {
      console.error(`Error fetching data for userId ${projectId}:`, err);
    }
  };

  useEffect(() => {
    fetchProjectData();
  }, [projectId]);

  

  const addEventsToCalendar = (eventDTOs) => {
    const calendarApi = calendarRef.current.getApi(); // Access the calendar API
    calendarApi.removeAllEvents(); // Clear existing events
    console.log(
      "Current FullCalendar timeZone:",
      calendarRef.current.getApi().getOption("timeZone")
    );

    eventDTOs.forEach((eventDTO) => {
      console.log("joe Adding event:", eventDTO);

      calendarApi.addEvent({
        id: eventDTO.eventId,
        title: eventDTO.title,
        start: eventDTO.start,
        end: eventDTO.end,
        allDay: eventDTO.allDay,
        backgroundColor: eventDTO.colourCode || '#3788d8', // Set background color
        borderColor: eventDTO.sharedWithClient ? '#000000': eventDTO.colourCode,
        icon:  eventDTO.sharedWithClient ? <PersonIcon />: "",
        extendedProps: {
          projectId: eventDTO.projectId,
          description:eventDTO.description,
          shareWithClient: eventDTO.sharedWithClient,
          employeeId: eventDTO.employeeId,
          businessId:eventDTO.businessId,
        },
      });

    });
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div 
        className="custom-event" 
        title={eventInfo.event.title}
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '25px',
        }}
      >
        {eventInfo.event.extendedProps.icon && (
          <span style={{ marginRight: '5px' }}>
            {eventInfo.event.extendedProps.icon}
          </span>
        )}
        <strong style={{ marginRight: '5px' }}>{eventInfo.timeText}</strong>
        <span>{eventInfo.event.title}</span>
      </div>
    );
  };

  const handleDateClick = async (selected) => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.CALLENDER)){
      alert("You are not authorized to create Calander Events. Please contact Admin for help.");
      return;
    }

    setSelectedDate(selected);
    setNewEventStartTime(selected.startStr);
    setNewEventEndTime(adjustEndDateForDisplay(selected.startStr, selected.endStr, selected.allDay));
    setShowTimeInputs(!selected.allDay);
    setIsEventDialogOpen(true);

    console.log(selected);
  };

  const handleEventSubmit = () => {

    if(isEdit){
      //editEvent();
      const isAllDay = !showTimeInputs;

      handleEditEvent1(
        selectedEvent,
        {
            eventId: eventToEdit.eventId, 
            title: newEventTitle,
            start: isAllDay ? formatDateToISO(newEventStartTime) : `${newEventStartTime}T${startTime}:00Z`,
            end: isAllDay ? formatEndDateToISO(newEventEndTime) : `${newEventEndTime}T${endTime}:00Z`,
            allDay: isAllDay,
            description: newDescription,
            sharedWithClient: shareWithClient,
            colourCode: eventBackgroundColor,
            employeeId: eventToEdit.employeeId,
            projectId: eventToEdit.projectId,
            businessId: eventToEdit.businessId,
        },
        calendarRef,
        setIsEventDialogOpen,
        setEventToEdit,
        handleError1
    );
      
    } else {
      handleEventCreation();
    }


  };

  /**
   * 
   * Code works fine
   * But i have pulled it out and moved it to a util so we can use it for calender and global calender
   */
  /*
  const editEvent = async () => {
    //todo: add data validation
    
    try {
      const isAllDay = !showTimeInputs;
      
      const event = {
        eventId: eventToEdit.eventId, 
        title: newEventTitle,
        start: isAllDay
        ? formatDateToISO(newEventStartTime)
        : `${newEventStartTime}T${startTime}:00Z`,
        end: isAllDay
        ? formatEndDateToISO(newEventEndTime)
        : `${newEventEndTime}T${endTime}:00Z`,
        allDay: isAllDay,
        description: newDescription,
        sharedWithClient: shareWithClient,
        colourCode: eventBackgroundColor,

        employeeId: eventToEdit.employeeId,
        projectId: eventToEdit.projectId,
        businessId: eventToEdit.businessId,

      };

      //todo: verify event data 
      const response = await axios.put(
        `${API_URL}/calander-event/edit`,
        event,
        {
          withCredentials: true,
          withXSRFToken: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Now update the event on the calendar UI
      const calendarApi = calendarRef.current.getApi();
      const calendarEvent = calendarApi.getEventById(event.eventId);



      if (calendarEvent) {
        calendarEvent.remove();  // Remove the existing event

        calendarApi.addEvent({
          id: response.data.eventId,
          title: response.data.title,
          start: response.data.start,
          end: response.data.end,
          allDay: response.data.allDay,
          backgroundColor: response.data.colourCode,
          icon: response.data.sharedWithClient ? <PersonIcon /> : "",
          extendedProps: {
            shareWithClient: response.data.sharedWithClient,
            description: response.data.description,
            projectId: response.data.projectId,
            employeeId: response.data.employeeId,
            businessId: response.data.businessId,
          },
        });
      }
      
    } catch (error){
       if (error.response) {
        if (error.response.status === 403){
          alert("You are not authorized to delete calander events. Please contact Admin for help.");
        }
      } else{
        window.confirm("Error deleting event:", error);
      }

    }

    setEventToEdit(null);
    setIsEdit(false);
    setIsEventDialogOpen(false);
    setNewEventTitle("");
    setNewEventStartTime("");
    setNewEventEndTime("");
    setStartTime("");
    setEndTime("");
    setShowTimeInputs(false);
    setShareWithClient(false);
    setNewDescription("");
    setEventBackgroundColor("#3788d8")
  };
  */

  const handleEventCreation = async () => {
    const calendarApi = selectedDate.view.calendar;
    calendarApi.unselect();

    let event;

    if (newEventTitle) {
      const isAllDay = !showTimeInputs;



      const event = {
        title: newEventTitle,
        start: isAllDay
          ? formatDateToISO(selectedDate.startStr)
          : `${newEventStartTime}T${startTime}:00Z`,
        end: isAllDay
          ? formatDateToISO(selectedDate.endStr)
          : `${newEventEndTime}T${endTime}:00Z`,
        allDay: isAllDay,
        employeeId: Cookies.get("userId"),
        projectId: projectId,
        businessId: Cookies.get("businessId"),
        sharedWithClient: shareWithClient,
        colourCode: eventBackgroundColor,
        description: newDescription,
      };

      try {
        // Post the form data to the backend to create a new document
        const response = await axios.post(`${API_URL}/calander-event/create`, event, {
          withCredentials: true,
          withXSRFToken: true,
          headers: {
            "Content-Type": "application/json",
          },
        });


      calendarApi.addEvent({
        id: response.data.eventId,
        title: newEventTitle,
        start: event.start,
        end: event.end,
        allDay: event.allDay,
        backgroundColor: eventBackgroundColor,
        icon: event.sharedWithClient ? <PersonIcon /> : "",
        extendedProps: {
          shareWithClient: shareWithClient,
          description: newDescription,
          projectId: event.projectId,
          employeeId: event.employeeId,
          businessId: event.businessId,
        },
      });
      } catch (error) {
        console.error("Error creating event:", error);
        if (error.response) {
          if (error.response.status === 403){
            alert("You are not authorized to create calander events. Please contact Admin for help.");
          }
        }
      }
    }

    setIsEventDialogOpen(false);
    setNewEventTitle("");
    setNewEventStartTime("");
    setNewEventEndTime("");
    setStartTime(getCurrentTime());
    setEndTime(getCurrentTime(1));
    setShowTimeInputs(false);
    setShareWithClient(false);
    setNewDescription("");
    setEventBackgroundColor("#3788d8")
  };

  const clearEventDialogUtil = () => {
    setIsEventDialogOpen(false);
    setNewEventTitle("");
    setNewEventStartTime("");
    setNewEventEndTime("");
    setStartTime(getCurrentTime());
    setEndTime(getCurrentTime(1));
    setShowTimeInputs(false);
    setShareWithClient(false);
    setNewDescription("");
    setEventBackgroundColor("#3788d8")
  };

  const formatDate = (dateStr) => {
    return new Date(dateStr).toLocaleDateString(); // Adjust the format as needed
  };




  const adjustEndDateForDisplay = (startStr, endStr, allDay) => {
    const startDate = new Date(startStr);
    const endDate = new Date(endStr);

    if (allDay) {
      // Subtract one day from the end date
      endDate.setDate(endDate.getDate() - 1);
    } else {
    }

    return endDate.toISOString().split("T")[0];
  };

  const handleEventClick = (clickInfo) => {
    setSelectedEvent({
      title: clickInfo.event.title,
      start: clickInfo.event.startStr,
      end: clickInfo.event.endStr,
      allDay: clickInfo.event.allDay,
      projectId: clickInfo.event.extendedProps.projectId,
      eventId: clickInfo.event.id,
      shareWithClient:clickInfo.event.extendedProps.shareWithClient,
      colourCode: clickInfo.event.backgroundColor,
      description: clickInfo.event.extendedProps.description,
      employeeId: clickInfo.event.extendedProps.employeeId,
      businessId: clickInfo.event.extendedProps.businessId,
    });
  }

  const handleClosEventSummaryDialog = () => {
    setSelectedEvent(null);
  };

  const handleEditEvent = async (selected) => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.CALLENDER)){
      alert("You are not authorized to edit Calander Events. Please contact Admin for help.");
      return;
    }

    console.log(selected);

    setIsEdit(true);
    setSelectedDate({
      startStr: selected.start,
      endStr: selected.end
    });

    // this is required to go from date-time to just the date
    const startDate = new Date(selected.start);
    setNewEventStartTime(startDate.toISOString().split("T")[0]);
    setNewEventEndTime(adjustEndDateForDisplay(selected.start, selected.end, selected.allDay));

    if(!selected.allDay){
      setEndTime(formatTime(selected.end));
      setStartTime(formatTime(selected.start));
      setShowTimeInputs(!selected.allDay);
    }
    
    setEventToEdit(selected);
    handleClosEventSummaryDialog();
    setNewEventTitle(selected.title);
    setShareWithClient(selected.shareWithClient);
    setNewDescription(selected.description);
    setEventBackgroundColor(selected.colourCode);
    setIsEventDialogOpen(true);
  };

  // Trigger the confirmation dialog when delete action is requested
  const handleDeleteEventRequest = (selected) => {
    setEventToDelete(selected);
    setIsConfirmationDialogOpen(true); // Open the confirmation dialog
  };

  const deleteEvent = () => {
    setIsConfirmationDialogOpen(false);
    handleDeleteEvent1(eventToDelete, calendarRef, setSelectedEvent, handleError1);

  }

  /*
    Tihs code works perfectly fine
    But i have now pulled it out and put it into a reuable util so we can do the same ish on both calender and global calendar
  */
  /*
  const handleDeleteEvent = async () => {
    setIsConfirmationDialogOpen(false);

    //ensure they have write access
    if(! await checkWriteAccess(ACCESS_CATEGORIES.CALLENDER)){
      alert("You are not authorized to delete Calander Events. Please contact Admin for help.");
      return;
    }
    
    try {
      const respose = await axios.delete(
        `${API_URL}/calander-event/delete/${eventToDelete.eventId}`,
        {
          withCredentials: true,
          withXSRFToken: true,
        }
      );

      setSelectedEvent(null)

      const calendarApi = calendarRef.current.getApi(); 
      const event = calendarApi.getEventById(eventToDelete.eventId); 
    
      if (event) {
        event.remove(); 
      }

    } catch (error) {
      if (error.response) {
        if (error.response.status === 403){
          alert("You are not authorized to delete calander events. Please contact Admin for help.");
        }
      } else{
        window.confirm("Error deleting event:", error);
      }
    }
    
  };
  */

  const handleShareWithClientChange = (event) => {
    setShareWithClient(event.target.checked);
  };

  const colorOptions = [
    { label: "DefaultBlue", value: "#3788d8" },
    { label: "yellow", value: "#ffD700" },
    { label: "Sage", value: "#FFB347" },
    { label: "Grape", value: "#54E9B7" },
    { label: "Flamingo", value: "#B1F46D" },
    { label: "Banana", value: "#6B936B" },
    { label: "Tangerine", value: "#3788D8" },
    { label: "Peacock", value: "#4B447E" },
    { label: "Graphite", value: "#C56BD1" },
    { label: "Blueberry", value: "#EC8178" },
    { label: "Basil", value: "#C71585" },
  ];

  const navigateTo = (path) => () => {
    navigate(path);
  };

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ width: "100%", mb: "5px", zIndex: 100, position: "relative" }}
      >
        <Box sx={{ ml: "20px", display: "flex", alignItems: "center" }}>
          {/* Clickable navigation links */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users`)}
          >
            Clients
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}`)}
          >
            Projects
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}/${projectId}`)}
          >
            {project.address}
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          <Typography variant="h6" component="span" fontWeight="bold">
            Calendar
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          {/* CALENDAR */}
          <Box flex="1 1 100%" m="20px">
            <FullCalendar
              ref={calendarRef}
              timeZone="utc"
              height="75vh"
              // timeZone="local"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,listMonth",
              }}
              initialView="dayGridMonth"
              editable={false}
              selectable={true}
              selectMirror={true}
              datesSet={() => setIsCalendarInitialized(true)} // Set calendar initialization state
              //events={allEvents}
              eventContent={renderEventContent}
              dayMaxEvents={true}
              select={handleDateClick}
              eventClick={handleEventClick}
              eventsSet={(events) => setCurrentEvents(events)}
              longPressDelay={20} // lower value for quicker response
              eventLongPressDelay={20}
            />
          </Box>
        </Grid>
      </Grid>

      {/* Event Creation Dialog */}
      {/*
      <Dialog
        open={isEventDialogOpen}
        onClose={() => clearEventDialogUtil()}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent>
          {selectedDate && selectedDate.startStr && (
            <TextField
            margin="dense"
            label="Start Date"
            type="date"
            fullWidth
            value={ newEventStartTime}
            onChange={(e) => setNewEventStartTime(e.target.value)}
            sx={{ input: { fontSize: "16px" } }}
            />
          )}

          {selectedDate && selectedDate.endStr && (
            <TextField
            margin="dense"
            label="End Date"
            type="date"
            fullWidth
            value={newEventEndTime}
            onChange={(e) => setNewEventEndTime(e.target.value)}
            sx={{ input: { fontSize: "16px" } }}
            />
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Event Title"
            type="text"
            fullWidth
            value={newEventTitle}
            onChange={(e) => setNewEventTitle(e.target.value)}
            sx={{ input: { fontSize: "16px" } }}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
            sx={{ input: { fontSize: "16px" } }}
            multiline // Enable multiline input
            maxRows={4} // Maximum number of rows to display before scrolling
            inputProps={{ maxLength: 250 }}
          />
          {showTimeInputs && (
            <>
              <TextField
                margin="dense"
                label="Start Time"
                type="time"
                fullWidth
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                sx={{ input: { fontSize: "16px" } }}
              />
              <TextField
                margin="dense"
                label="End Time"
                type="time"
                fullWidth
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                sx={{ input: { fontSize: "16px" } }}
              />
            </>
          )}

          <FormControlLabel
            control={
              <Checkbox
                checked={shareWithClient}
                onChange={handleShareWithClientChange}
                name="myCheckbox"
                color="primary"
              />
            }
            label="Share event with client"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showTimeInputs}
                onChange={() => setShowTimeInputs(!showTimeInputs)}
              />
            }
            label="Add Time"
          />
          <FormControl sx={{minWidth: 70, margin:"dense"}}>
            <Select
              value={eventBackgroundColor}
              onChange={(e) => setEventBackgroundColor(e.target.value)}
              sx={{ fontSize: "16px" }}
            >
              {colorOptions.map((color) => (
                <MenuItem key={color.value} value={color.value}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: color.value,
                        borderRadius: "50%",
                        width: 20,
                        height: 20,
                        marginRight: 1,
                      }}
                    />
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => clearEventDialogUtil()}>Cancel</Button>
          <Button onClick={handleEventSubmit}>Create Event</Button>
        </DialogActions>
      </Dialog>
      */}


      {/*testing event dialog component*/}
      {/* Dialog for editing/creating event*/}
      <EventDialog
        isOpen={isEventDialogOpen}
        handleClose={clearEventDialogUtil}
        handleSubmit={handleEventSubmit}
        eventTitle={newEventTitle}
        setEventTitle={setNewEventTitle}
        eventStartTime={newEventStartTime}
        setEventStartTime={setNewEventStartTime}
        eventEndTime={newEventEndTime}
        setEventEndTime={setNewEventEndTime}
        showTimeInputs={showTimeInputs}
        setShowTimeInputs={setShowTimeInputs}
        shareWithClient={shareWithClient}
        setShareWithClient={setShareWithClient}
        eventDescription={newDescription}
        setEventDescription={setNewDescription}
        eventBackgroundColor={eventBackgroundColor}
        setEventBackgroundColor={setEventBackgroundColor}
        //colorOptions={colorOptions}
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
      />

      {/* Dialog for viewing event Details*/}
      {selectedEvent && (
        <EventDetailsDialog
          selectedEvent={selectedEvent}
          setSelectedEvent={handleClosEventSummaryDialog}
          hasAbilityToEdit={true}
          projectPage={true}
          handleDeleteEvent={handleDeleteEventRequest}
          handleEditEvent={handleEditEvent}
        />
      )}

      {/* ConfirmationDialog for deleting event */}
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={() => setIsConfirmationDialogOpen(false)}
        onConfirm={deleteEvent} // Call the delete event handler on confirm
        title="Delete Event"
        message={`Are you sure you want to delete the event '${eventToDelete?.title}'?`}
      />
    </Box>
  );
};

export default Calendar;
