import { useState, useEffect, useRef } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  useTheme,
  Grid,
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "../../CalendarStyles.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EventDetailsDialog from "../dashboard/EventDetailsDialog";
import PersonIcon from '@mui/icons-material/Person';
import { formatDateToISO,formatEndDateToISO, formatTime, getCurrentTime, handleDeleteEvent1, handleEditEvent1, handleError1 } from '../calendar/calendarUtil';
import EventDialog from "../calendar/eventDialog";
import ConfirmationDialog from "../../generic/ConfirmationDialog";
import ACCESS_CATEGORIES from "../../constants/AccessCatagories"
import checkWriteAccess from "../../util/CheckWriteAccess";





const GlobalCalendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentEvents, setCurrentEvents] = useState([]);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allEvents, setAllEvents] = useState([]);
  const [isCalendarInitialized, setIsCalendarInitialized] = useState(false);
  const [isEventDialogOpen, setIsEventDialogOpen] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false); // State for confirmation dialog (used for deleting event)

  const [newEventStartTime, setNewEventStartTime] = useState("");
  const [newEventEndTime, setNewEventEndTime] = useState("");
  const [startTime, setStartTime] = useState(getCurrentTime());
  const [endTime, setEndTime] = useState(getCurrentTime(1));
  const [showTimeInputs, setShowTimeInputs] = useState(false);
  const [newEventTitle, setNewEventTitle] = useState("");
  const [shareWithClient, setShareWithClient] = useState(false);
  const [newDescription, setNewDescription] = useState("");
  const [eventBackgroundColor, setEventBackgroundColor] = useState("#3788d8"); 


  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (isCalendarInitialized) {
      fetchEvents();
    }
  }, [isCalendarInitialized]);

  // ref to store the calendar instance
  const calendarRef = useRef(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/calander-event/get-event-for-userId/${Cookies.get(
          "userId"
        )}`,
        { withCredentials: true }
      );
      const eventDTOs = response.data;

      //setAllEvents(eventDTOs);
      addEventsToCalendar(eventDTOs);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching events:", error);
      if (error.response) {
        if (error.response.status === 403){
          alert("You are not authorized to view calander events. Please contact Admin for help.");
        }
      }
      // Handle error appropriately
    }
  };

  const handleEventClick = (clickInfo) => {
    console.log(clickInfo.event.extendedProps.projectId);

    setSelectedEvent({
      title: clickInfo.event.title,
      start: clickInfo.event.startStr,
      end: clickInfo.event.endStr,
      allDay: clickInfo.event.allDay,
      projectId: clickInfo.event.extendedProps.projectId,
      eventId: clickInfo.event.id,
      shareWithClient:clickInfo.event.extendedProps.shareWithClient,
      colourCode: clickInfo.event.backgroundColor,
      description: clickInfo.event.extendedProps.description,
      employeeId: clickInfo.event.extendedProps.employeeId,
      businessId: clickInfo.event.extendedProps.businessId,
    });
  };

  const handleEventSubmit = () => {
    if (isEdit) {
      const isAllDay = !showTimeInputs;

      console.log("joe testing submit!");
      handleEditEvent1(
        selectedEvent,
        {
          eventId: eventToEdit.eventId, 
          title: newEventTitle,
          start: isAllDay ? formatDateToISO(newEventStartTime) : `${newEventStartTime}T${startTime}:00Z`,
          end: isAllDay ? formatEndDateToISO(newEventEndTime) : `${newEventEndTime}T${endTime}:00Z`,
          allDay: isAllDay,
          description: newDescription,
          sharedWithClient: shareWithClient,
          colourCode: eventBackgroundColor,
          employeeId: eventToEdit.employeeId,
          projectId: eventToEdit.projectId,
          businessId: eventToEdit.businessId,
      },
        calendarRef,
        setIsEventDialogOpen,
        setEventToEdit,
        handleError1
      );
    }
  };

  // tbd if I will keep these states and this method
  // i am trying to make it so that you can edit from the global cal page

            
            const adjustEndDateForDisplay = (startStr, endStr, allDay) => {
              const startDate = new Date(startStr);
              const endDate = new Date(endStr);

              if (allDay) {
                // Subtract one day from the end date
                endDate.setDate(endDate.getDate() - 1);
              } else {
              }

              return endDate.toISOString().split("T")[0];
            };

  const handleEditEvent = async (selected) => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.CALLENDER)){
      alert("You are not authorized to edit Calander Events. Please contact Admin for help.");
      return;
    }


    setIsEdit(true);


    // this is required to go from date-time to just the date
    const startDate = new Date(selected.start);
    setNewEventStartTime(startDate.toISOString().split("T")[0]);
    setNewEventEndTime(adjustEndDateForDisplay(selected.start, selected.end, selected.allDay));
    console.log("joe was here: 2");


    if(!selected.allDay){
      setEndTime(formatTime(selected.end));
      setStartTime(formatTime(selected.start));
      setShowTimeInputs(!selected.allDay);
    }
    console.log("joe was here: 3");

    
    setEventToEdit(selected);
    handleClosEventSummaryDialog();
    setNewEventTitle(selected.title);
    setShareWithClient(selected.shareWithClient);
    setNewDescription(selected.description);
    setEventBackgroundColor(selected.colourCode);
    setIsEventDialogOpen(true);
    console.log("joe was here: 4");

  };

  const handleDeleteEventRequest = () => {
    setIsConfirmationDialogOpen(true);
  };

  const deleteEvent = () => {
    setIsConfirmationDialogOpen(false);
    handleDeleteEvent1(selectedEvent, calendarRef, setSelectedEvent, handleError1);
  };

  const handleClosEventSummaryDialog = () => {
    setSelectedEvent(null);
  };

  const addEventsToCalendar = (eventDTOs) => {
    const calendarApi = calendarRef.current.getApi(); // Access the calendar API
    calendarApi.removeAllEvents();

    eventDTOs.forEach((eventDTO) => {
      calendarApi.addEvent({
        id: eventDTO.eventId,
        title: eventDTO.title,
        start: eventDTO.start,
        end: eventDTO.end,
        allDay: eventDTO.allDay,
        backgroundColor: eventDTO.colourCode || '#3788d8', // Set background color
        borderColor: eventDTO.sharedWithClient ? '#000000': eventDTO.colourCode,
        icon:  eventDTO.sharedWithClient ? <PersonIcon />: "",
        extendedProps:{
          projectId: eventDTO.projectId,
          description:eventDTO.description,
          shareWithClient: eventDTO.sharedWithClient,
          employeeId: eventDTO.employeeId,
          businessId:eventDTO.businessId,
        }
      });
    });
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div 
        className="custom-event" 
        title={eventInfo.event.title}
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '25px',
        }}
      >
        {eventInfo.event.extendedProps.icon && (
          <span style={{ marginRight: '5px' }}>
            {eventInfo.event.extendedProps.icon}
          </span>
        )}
        <strong style={{ marginRight: '5px' }}>{eventInfo.timeText}</strong>
        <span>{eventInfo.event.title}</span>
      </div>
    );
  };

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Calendar" subtitle="Employee Calendar" />
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          {/* CALENDAR */}
          <Box flex="1 1 100%" ml="15px">
            <FullCalendar
              ref={calendarRef}
              timeZone="utc"
              height="75vh"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,listMonth",
              }}
              initialView="dayGridMonth"
              editable={false}
              // selectable={true}
              selectMirror={true}
              //events={allEvents}
              datesSet={() => setIsCalendarInitialized(true)}
              eventContent={renderEventContent}
              dayMaxEvents={true}
              // select={handleDateClick}
              eventClick={handleEventClick}
              //eventsSet={(events) => setCurrentEvents(events)}
            />
          </Box>
        </Grid>
      </Grid>
      {selectedEvent && (
        <EventDetailsDialog
          selectedEvent={selectedEvent}
          setSelectedEvent={handleClosEventSummaryDialog}
          hasAbilityToEdit={true}
          handleDeleteEvent={handleDeleteEventRequest}
          handleEditEvent={handleEditEvent}
          projectPage={false}
        />
      )}

      {isEventDialogOpen && (
        <EventDialog
          isOpen={isEventDialogOpen}
          handleClose={() => setIsEventDialogOpen(false)}
          handleSubmit={handleEventSubmit}
          eventTitle={newEventTitle}
          setEventTitle={setNewEventTitle}
          eventStartTime={newEventStartTime}
          setEventStartTime={setNewEventStartTime}
          eventEndTime={newEventEndTime}
          setEventEndTime={setNewEventEndTime}
          showTimeInputs={showTimeInputs}
          setShowTimeInputs={setShowTimeInputs}
          shareWithClient={shareWithClient}
          setShareWithClient={setShareWithClient}
          eventDescription={newDescription}
          setEventDescription={setNewDescription}
          eventBackgroundColor={eventBackgroundColor}
          setEventBackgroundColor={setEventBackgroundColor}
          //colorOptions={colorOptions}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
        />
      )}

      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={() => setIsConfirmationDialogOpen(false)}
        onConfirm={deleteEvent} // Call the delete event handler on confirm
        title="Delete Event"
        message={`Are you sure you want to delete the event '${selectedEvent?.title}'?`}
      />
    </Box>
  );
};

export default GlobalCalendar;
